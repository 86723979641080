import React from "react";
import Layout from "../components/layout";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { FaLinkedin } from "react-icons/fa";

const ContactPage = () => (
  <Layout title="Contact">
    <section className="container">
      <div className="row">
        <div className="col-2">
        </div>
        <div className="col-8 center">   
          <p>Faute de mieux pour l'instant, le plus simple reste de me contacter via <OutboundLink
              href="https://www.linkedin.com/in/jlethuaut/"
              target="_blank"
              rel="noreferrer"
              style={{ color: '#0a66c2', fontWeight: '700'}}
            >
              Linked<FaLinkedin size={30} style={{ fill: '#0a66c2', verticalAlign: 'text-bottom' }}/>
            </OutboundLink>
          </p>
        </div>
        <div className="col-2">
        </div>
      </div>
    </section>
  </Layout>
);

export default ContactPage;
